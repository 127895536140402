<template>
  <div
    ref="tmapRef"
    class="tmap"
    :class="{
      'hidden-inspect-1': !showInspectStatus.includes('1'),
      'hidden-inspect-2': !showInspectStatus.includes('2'),
      'hidden-inspect-3': !showInspectStatus.includes('3')
    }"
    :id="id"
  ></div>
  <div class="copy-box" v-show="id === 'deviceInfosId'">
    <div>
      <el-input
        ref="copyInputRef"
        v-model="lnglatRes"
        readonly
        :input-style="{ border: 0, cursor: 'default' }"
      />
    </div>
    <el-button type="primary" icon="el-icon-document-copy" @click="clickCopyText"></el-button>
  </div>
</template>

<script>
import 'ol/ol.css'
import View from 'ol/View'
import OLMap from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import WMTS from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { get as getProjection } from 'ol/proj'
import { getTopLeft, getWidth } from 'ol/extent'
import Overlay from 'ol/Overlay'
import Collection from 'ol/Collection'

import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { BASEURL, FILEPREVIEWURL } from '@API'
import { formetData, getPropertyType } from '@utils'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'TAmp',
  emits: [
    'submit-btn',
    'view-error-btn',
    'click-amoeba-head-markers',
    'view-prview-project',
    'inspect-layer-click',
    'map-click'
  ],
  props: {
    id: {
      type: String,
      required: true,
      default: 'T-Map-id'
    },
    list: {
      type: Array,
      default: () => []
    },
    center: {
      type: Array,
      default: () => []
    },
    tampDeviceId: {
      type: String,
      default: ''
    },
    tampProjectId: {
      type: String,
      default: ''
    },
    mapStyle: {
      type: String,
      default: ''
    },
    // 这个参数控制巡检点可见类型
    showInspectStatus: {
      type: Array,
      default: () => []
    }
  },
  setup(props, content) {
    const { dispatch } = useStore()
    const route = useRoute()
    const nList = computed(() => props.list)

    // 实例地图
    const tmapRef = ref()
    let map = null
    let view = null
    const tdtToken = '5c275280719a00359dc278644161d0f0'

    // 获取设备详情
    const getDeviceInfo = async equipmentId => {
      const data = await dispatch('fetchGetDeviceInfo', equipmentId)
      return data.data || []
    }

    // 设备信息内容高度
    const deviceMaxHeight = 150

    // 是否点击查看
    let isClickInfo = false
    watch(
      () => props.tampDeviceId,
      newvalue => {
        nextTick(() => {
          if (tampDeviceFn && newvalue) {
            tampDeviceFn(newvalue)
          }
        })
      }
    )

    watch(
      () => props.tampProjectId,
      newvalue => {
        nextTick(() => {
          if (tampProjectFn && newvalue) {
            tampProjectFn(newvalue)
          }
        })
      }
    )
    // device弹窗方法
    let tampDeviceFn = async (id, marker) => {
      if (!id) {
        return
      }
      const dId = id

      const deviceInfo = await getDeviceInfo(dId)
      // 视频，图片
      const imgDom = deviceInfo.imagePath
        ? `<img id="imgId" style="width: 300px; height: 150px" src="${BASEURL}${FILEPREVIEWURL}/${deviceInfo.imagePath}" />`
        : ``
      const videoDom = deviceInfo.videoPath
        ? `<video style="display: none" width="300" height="150" controls autoPlay id="videoId">
                      <source src="${deviceInfo.videoPath}" >
                    </video>`
        : ``
      const videoBox =
        videoDom || imgDom
          ? `<div class="device-infowin-box-video" id="videoBoxId">
                         ${videoDom}
                         ${imgDom}
                       </div>`
          : ''

      // 设备特殊属性

      let specialType = ''
      if (
        deviceInfo.deviceAttrs != null &&
        deviceInfo.deviceAttrs != 'null' &&
        deviceInfo.deviceAttrs.length > 0
      ) {
        deviceInfo.deviceAttrs.map(v => {
          specialType += `<div>${v.propertyName}：${v.propertyValue}
                    ${v.unit || ''}
                    </div>`
        })
      }

      const specialTypeRow = specialType
        ? `<div class="device-infowin-box-body-row active-div">
                        ${specialType}
                       </div>`
        : ''

      // 告警时间
      const errorTimeHtml =
        deviceInfo.errorStatus === 'Y' ? `<div>告警时间:${deviceInfo.errorTime}</div>` : ''
      // 采集设备属性
      const collectDevicePropsDom =
        deviceInfo.deviceType === '采集设备-暂不采用'
          ? `<div>观测时间：${formetData(new Date())}</div>
                       <div>通信状态：<span id="socketTypeId">异常</span></div>
                       ${specialTypeRow}
                       `
          : `<div>设备类型：${deviceInfo.deviceType}</div>
                       <div>设备位置：${deviceInfo.address}</div>
                       <div>经纬度：${Number(deviceInfo.longitude || 0).toFixed(2)} / ${Number(
              deviceInfo.latitude || 0
            ).toFixed(2)}</div>
                       <div>设备状态：${
                         deviceInfo.errorStatus === 'Y'
                           ? '故障'
                           : deviceInfo.onlineStatus === 'Y'
                           ? '正常'
                           : '离线'
                       }</div>
                       <div>所属阿米巴：${deviceInfo.companyName}</div>
                       ${specialTypeRow}
                       <div>更新时间：${deviceInfo.updateTime}</div>
                       `

      // 标记弹框
      const deviceMarkersContent = `<div class="device-infowin-box">
                  ${videoBox}
                  <div class="device-infowin-box-body">
                    <div class="device-box" style="max-height:${deviceMaxHeight}px;overflow:hidden;">
                      <div>设备名称：${deviceInfo.deviceName}</div>
                      ${collectDevicePropsDom}
                      ${errorTimeHtml}
                    </div> 
                    <p class="siwtch-tool" data-switch="zoom">
                      <i class="el-icon-arrow-down"></i>
                    </p>
                    <div class="device-infowin-box-body-btn"><button id="submit-btn">故障上报</button></div>
                  </div>
                </div>`
      // popupOverlay.setElement(createInfoPopupWin(deviceMarkersContent))
      popupOverlayContent.innerHTML = deviceMarkersContent
      popupOverlay.setPosition(
        marker ? marker.getPosition() : [deviceInfo.longitude, deviceInfo.latitude]
      )

      // 设备信息节点
      let deviceElement = document.getElementsByClassName('device-box')
      let deviceHeight = deviceElement[0].scrollHeight
      const siwtchEl = document.getElementsByClassName('siwtch-tool')[0]

      // 是否显示 展示设备信息 按钮
      if (deviceHeight > deviceMaxHeight) {
        siwtchEl.style.display = 'block'
      } else {
        siwtchEl.style.display = 'none'
      }
      startSwitch()

      // 上报按钮
      const submitBtn = document.getElementById('submit-btn')
      // video el
      const imgId = document.getElementById('imgId')
      const videoId = document.getElementById('videoId')
      if (videoId) {
        const socketTypeEl = document.getElementById('socketTypeId')
        videoId.addEventListener('canplay', () => {
          socketTypeEl.innerText = '正常'
          videoId.style.display = 'block'
          imgId.style.display = 'none'
        })
      }
      submitBtn.addEventListener('click', () => {
        content.emit('submit-btn', deviceInfo)
      })
    }
    // 启动点击 展示伸缩
    const startSwitch = () => {
      const elem = document.getElementsByClassName('siwtch-tool')
      // 设备信息节点
      let deviceElement = document.getElementsByClassName('device-box')
      elem[0].onclick = function () {
        const attr = elem[0].getAttribute('data-switch')

        // 要展开
        if (attr == 'zoom') {
          deviceElement[0].style.maxHeight = `100%`
          deviceElement[0].style.overflow = `initial`
          elem[0].setAttribute('data-switch', 'all')
          elem[0].innerHTML = '<i class="el-icon-arrow-up"></i>'
        } else {
          //缩放
          deviceElement[0].style.maxHeight = `${deviceMaxHeight}px`
          deviceElement[0].style.overflow = `hidden`
          elem[0].setAttribute('data-switch', 'zoom')
          elem[0].innerHTML = '<i class="el-icon-arrow-down"></i>'
        }
      }
    }

    // project弹窗方法
    let tampProjectFn = async (id, marker) => {
      if (!id) {
        return
      }
      isClickInfo = true
      const pid = id
      const { data } = await dispatch('fetchGetProjectInfo', pid)

      // 查看左侧项目信息
      content.emit('view-prview-project', pid)

      const projectInfo = data
      const deviceCountVo = projectInfo.deviceCountVo || {}
      // 标记弹框
      const projectContent = `<div class="device-infowin-box" id="infowWindow">
				<div class="device-infowin-box-body">
					<div>项目基础信息</div>
					<div>项目名称：${projectInfo.projectName}</div>
					<div>维护单位：${projectInfo.companyName}</div>
					<div>项目经理：${projectInfo.projectManager}</div>
					<div>联系电话：${projectInfo.managerPhone}</div>
					<div>维护负责人：${projectInfo.mainOpsUser}</div>
					<div>联系电话：${projectInfo.leaderPhone}</div>
					<div>设备信息</div>
					<div class="device-infowin-box-body-row">
						<div>设备总数：${deviceCountVo.deviceCount || 0}</div>
						<div>受监控设备总数：${deviceCountVo.monitorCount || 0}</div>
					</div>
					<div class="device-infowin-box-body-row">
						<div>故障设备总数：${deviceCountVo.errorCount || 0}</div>
						<div>已处理故障总数：${deviceCountVo.completeCount || 0}</div>
					</div>
					<div class="device-infowin-box-body-btn"><button id="view-error-btn">查看所有故障</button></div>
				</div>
			</div>`

      // 暂不显示窗口内容
      // 当前 不是阿米巴页
      const isNotAMB = route.path.indexOf('/amoebaHead') == -1
      if (isNotAMB) {
        // popupOverlay.setElement(createInfoPopupWin(projectContent))
        popupOverlayContent.innerHTML = projectContent
        popupOverlay.setPosition(
          marker ? marker.getPosition() : [projectInfo.longitude, projectInfo.latitude]
        )
        // 查看故障操作
        const viewErrorBtn = document.getElementById('view-error-btn')
        if (viewErrorBtn) {
          viewErrorBtn.addEventListener('click', () => {
            content.emit('view-error-btn', projectInfo)
          })
        }
      }
    }
    // 配置
    const config = reactive({
      // 中心点
      center: '',
      // 层级
      zoom: 10
    })

    const lnglatRes = ref('')

    // 复制
    const copyInputRef = ref()
    const clickCopyText = () => {
      copyInputRef.value.select()
      document.execCommand('copy')
    }

    // 地图绘点相关接口
    const clearOverLays = () => {
      map.getOverlays().clear()
    }
    const createMarker = (point, opts, attributes = {}) => {
      if (point && point.length >= 2) {
        let dx = parseFloat(point[0])
        let dy = parseFloat(point[1])
        if (dx && dy) {
          let container = document.createElement('img')
          // 设置鼠标移入样式.
          container.style.cursor = 'pointer'
          // 图片
          container.src = `${opts.url}`
          // 样式
          let elWidth = opts.width || 20
          let elHeight = opts.height || 20
          let elScale = opts.scale || 1
          //
          container.alt = `noData`
          container.style.width = `${elWidth * elScale}px`
          container.style.height = `${elHeight * elScale}px`
          let overlay = new Overlay({
            //
            element: container,
            positioning: 'center-center',
            position: [dx, dy],
            stopEvent: false,
            className: opts.className
          })
          //
          overlay.attributes = attributes
          return overlay
        }
      }
      //
    }
    // 弹窗初始化
    let popupOverlay, popupOverlayContent
    const createInfoPopupOverlay = () => {
      let domNode = createInfoPopupWin()
      let infoPopupOverlay = new Overlay({
        //
        element: domNode,
        position: [0, 0],
        offset: [0, -20],
        positioning: 'bottom-center'
        // stopEvent:false
      })
      map.addOverlay(infoPopupOverlay)
      infoPopupOverlay.setPosition(undefined)
      return infoPopupOverlay
    }
    const createInfoPopupWin = () => {
      let winDiv = document.createElement('div')
      winDiv.classList.add('dse-map-popup')
      let btnClose = document.createElement('a')
      btnClose.classList.add('dse-map-popup-close')
      btnClose.innerText = '×'
      btnClose.onclick = () => {
        popupOverlay.setPosition(null)
        // popupOverlay.setElement(null)
        popupOverlayContent.innerHTML = ''
      }
      let body = document.createElement('div')
      body.classList.add('dse-map-popup-body')
      let content = document.createElement('div')
      content.classList.add('dse-map-popup-body-content')
      popupOverlayContent = content
      winDiv.append(btnClose)
      body.append(content)
      winDiv.append(body)

      return winDiv
    }
    // 添加数据
    const setMarkers = () => {
      clearOverLays()

      if (nList.value.length > 0) {
        // 触碰 项目窗口
        popupOverlay = createInfoPopupOverlay()

        // config.center = [nList.value[0].longitude, nList.value[0].latitude]
        nList.value.map(item => {
          // 标记
          if (!item.longitude || !item.latitude) {
            return
          }
          const markersPoint = [item.longitude, item.latitude]
          if (props.id === 'deviceInfosId') {
            const nDeviceType = getPropertyType(item.deviceType)
            let deviceIcon
            let size
            // const deviceIcon =
            //   item.onlineStatus === 'Y' ? `${nDeviceType}.png` : `${nDeviceType}.gif`
            // const size = item.onlineStatus === 'Y' ? 25 : 50
            // const sizeAnchor = item.onlineStatus === 'Y' ? 12.5 : 25

            /*
								正常  蓝色 iotStatus = 1 and  onlineStatus ='Y' and errorStatus = 'N'
								故障  红色  onlineStatus = 'N' or errorStatus = 'Y'
								未接入 灰色 iotStatus = 0 and errorStatus = 'N'
							*/
            if (item.iotStatus === 0 && item.errorStatus === 'N') {
              deviceIcon = `${nDeviceType}-g.png`
              size = 26
            } else if (
              item.iotStatus === 1 &&
              item.onlineStatus === 'Y' &&
              item.errorStatus === 'N'
            ) {
              deviceIcon = `${nDeviceType}.png`
              size = 25
            } else {
              deviceIcon = `${nDeviceType}.gif`
              size = 50
            }

            let marker = createMarker(markersPoint, {
              url: require(`../assets/image/map/${deviceIcon}`),
              width: size,
              height: size,
              scale: 1
            })
            if (!marker) {
              return
            }

            marker.getElement().addEventListener('click', () => {
              tampDeviceFn(item.deviceId, marker)
            })

            map.addOverlay(marker)
            // tmap.value.centerAndZoom(config.center, 10)
          } else if (props.id === 'projectLayerId') {
            const projectIcon =
              item.status === 'N'
                ? '项目-g.png'
                : item.errorStatus === 'Y'
                ? '项目.gif'
                : '项目.png'
            const size = item.status === 'N' ? 26 : item.errorStatus === 'Y' ? 50 : 25

            let marker = createMarker(markersPoint, {
              url: require(`../assets/image/map/${projectIcon}`),
              width: size,
              height: size,
              scale: 1
            })
            if (!marker) {
              return
            }

            marker.getElement().addEventListener('click', () => {
              tampProjectFn(item.projectId)
            })

            // 触碰标点
            marker.getElement().addEventListener('mouseover', res => {
              if (!document.getElementById('infowWindow')) {
                isClickInfo = false
              } else {
                return
              }

              // 设置内容、打开窗口
              const mouseInfoContent = `
									<div class="device-infowin-box">
										<div class="device-infowin-box-body">
											<div>项目名称：${item.projectName}</div>
										</div>
									</div>
								`

              // popupOverlay.setElement(createInfoPopupWin(mouseInfoContent))
              popupOverlayContent.innerHTML = mouseInfoContent
              popupOverlay.setPosition(marker.getPosition())
            })

            map.addOverlay(marker)
            // tmap.value.centerAndZoom(config.center, 10)
          } else if (props.id === 'amoebaHeadViewId') {
            const projectIcon =
              item.status === 'N'
                ? 'circle-g.png'
                : item.errorStatus === 'Y'
                ? 'circle-r.png'
                : 'circle-b.png'
            const size = item.status === 'N' ? 25 : item.errorStatus === 'Y' ? 25 : 25

            let marker = createMarker(markersPoint, {
              url: require(`../assets/image/map/${projectIcon}`),
              width: size,
              height: size,
              scale: 1
            })
            if (!marker) {
              return
            }
            marker.getElement().addEventListener('click', () => {
              content.emit('click-amoeba-head-markers', item.projectId)
            })
            map.addOverlay(marker)
          } else if (props.id === 'inspectLayerId') {
            const projectIcon =
              item.status == 3 ? '项目-g.png' : item.status == 2 ? '项目.gif' : '项目.png'
            const size = item.status == 2 ? 50 : 25

            let marker = createMarker(markersPoint, {
              url: require(`../assets/image/map/${projectIcon}`),
              width: size,
              height: size,
              scale: 1,
              className: 'inspect-layer-' + item.status
            })
            if (!marker) {
              return
            }

            // 触碰标点
            marker.getElement().addEventListener('click', e => {
              content.emit('inspect-layer-click', item)
            })
            marker.getElement().addEventListener('mouseover', res => {
              if (!document.getElementById('infowWindow')) {
                isClickInfo = false
              } else {
                return
              }

              // 设置内容、打开窗口
              const mouseInfoContent = `
									<div class="device-infowin-box">
										<div class="device-infowin-box-body">
											<div>任务名称：${item.taskName}</div>
										</div>
									</div>
								`
              popupOverlayContent.innerHTML = mouseInfoContent
              popupOverlay.setPosition(marker.getPosition())
            })

            map.addOverlay(marker)
          } else if (props.id === 'inspectPlanId') {
            let marker = createMarker(markersPoint, {
              url: require(`../assets/image/map/location.png`),
              width: 32,
              height: 64,
              scale: 1,
              className: 'map-location'
            })
            if (!marker) {
              return
            }
            map.addOverlay(marker)
          }
        })

        const locations = nList.value[0]
        const lat = locations ? locations.latitude : '22.94322'
        const long = locations ? locations.longitude : '113.38715'

        config.center = [props.center[0] || long, props.center[1] || lat]
      } else {
        clearOverLays()
      }

      setTimeout(() => {
        view.animate({
          center: config.center,
          zoom: 10,
          duration: 300
        })
      }, 100)
    }

    /**
     * 获取天地图wmts
     */
    let projection = getProjection('EPSG:4326')
    let projectionExtent = projection.getExtent()
    let size = getWidth(projectionExtent) / 256
    let resolutions = []
    let matrixIds = []
    for (let z = 0; z < 19; ++z) {
      resolutions[z] = size / Math.pow(2, z)
      matrixIds[z] = z
    }
    const getTdtTileLayer = (type, opts) => {
      let matrixSet = 'c'
      let urlPrefix = `http://t{1-7}.tianditu.gov.cn/${type}_${matrixSet}/wmts?tk=${tdtToken}`
      //
      let tdtLayer = new TileLayer({
        source: new WMTS({
          // url: `http://t0.tianditu.gov.cn/vec_w/wmts?tk=${layerCfg.tdtToken}`,
          url: urlPrefix,
          layer: type,
          matrixSet: matrixSet,
          // format: 'image/png',
          format: 'tiles',
          projection: projection,
          crossOrigin: 'anonymous',

          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds
          }),
          style: 'default',
          wrapX: true
        }),
        ...opts
      })
      return tdtLayer
    }
    // 实例方法
    const readTmap = type => {
      view = new View({
        // 设置地图的可视区域，center为中心点，zoom为缩放的层级
        center: [113.38715, 22.94322],
        zoom: 10,
        projection: `EPSG:4326`,
        minZoom: 4,
        maxZoom: 18
      })
      //创建对象
      const lay = getTdtTileLayer('img', { minZoom: 4, maxZoom: 18 })
      const layT = getTdtTileLayer('cia', { minZoom: 4, maxZoom: 18 })
      const cvT = getTdtTileLayer('cva', { minZoom: 4, maxZoom: 18 })
      const vecT = getTdtTileLayer('vec', { minZoom: 4, maxZoom: 18 })
      if (!map) {
        map = new OLMap({
          target: document.getElementById(props.id),
          layers: [vecT, cvT],
          controls: [],
          view: view
        })
      } else {
        const layersToAdd = type === 'img' ? [lay, layT] : [vecT, cvT]
        map.setLayers(new Collection(layersToAdd))
      }

      map.on('click', e => {
        lnglatRes.value = `${e.coordinate[0].toFixed(6)} ${e.coordinate[1].toFixed(6)}`
        content.emit('map-click', [e.coordinate[0].toFixed(6), e.coordinate[1].toFixed(6)])
      })
    }
    watch(
      () => nList.value,
      nVal => {
        // if (nVal[0]) {
        //   view.animate({
        //     zoom: config.zoom,
        //     center: [nVal[0].longitude, nVal[0].latitude],
        //     duration: 300
        //   })
        // }
        setMarkers()
      }
    )
    watch(
      () => props.center,
      newvalue => {
        if (newvalue && newvalue.length >= 2) {
          // setCenter(nT.value, newvalue)
          config.center = [newvalue[0] || 113.38715, newvalue[1] || 22.94322]
        }
      }
    )
    onMounted(() => {
      readTmap()
    })

    return { tmapRef, lnglatRes, clickCopyText, copyInputRef, readTmap }
  }
}
</script>

<style lang="scss">
.tdt-infowindow-content {
  width: auto !important;
}

.tmap {
  @extend %params-global;
  width: 100%;
  height: 100%;

  .projectWindow {
    width: 1200px;
    padding: 0 10px;

    &-title {
      font-size: 16px;
      font-weight: 700;
      margin: 10px 0;
    }

    &-body {
      display: flex;

      > div {
        margin: 10px;
        flex: 1;
      }
    }
  }

  &.hidden-inspect-1 {
    .inspect-layer-1 {
      visibility: hidden;
    }
  }
  &.hidden-inspect-2 {
    .inspect-layer-2 {
      visibility: hidden;
    }
  }
  &.hidden-inspect-3 {
    .inspect-layer-3 {
      visibility: hidden;
    }
  }
}

.copy-box {
  position: absolute;
  bottom: 3px;
  right: 260px;
  color: #001528;
  z-index: 400;
  background: #fff;
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  overflow: hidden;
  border-radius: 5px;
  transform: scale(0.8);

  input::selection {
    background-color: #fff;
    color: #606266;
  }
}

.device-box {
  // max-height: 300px;
  // overflow-y: auto;
  display: flex;
  flex-direction: column !important;
  flex-wrap: nowrap !important;

  > div {
    min-height: 20px;
    line-height: 20px;
    margin: 10px 0;

    &.active-div {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}

.siwtch-tool {
  text-align: center;
}
.dse-map-popup {
  &-body {
    padding: 1px;
    border-radius: 10px;
    background: #00152899;
    &-content {
      width: auto !important;
      margin: 13px 19px;
      line-height: 1.4;
    }
    &:before {
      position: absolute;
      height: 0;
      width: 0;
      top: 100%;
      left: 50%;
      content: ' ';
      border: solid transparent;
      border-top-color: #00152899;
      border-width: 11px;
      margin-left: -11px;
      pointer-events: none;
    }
  }
  &-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: 700;
    background: 0 0;
  }
}
</style>
